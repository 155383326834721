/*
 * @Description:
 * @Author: xiaoq
 * @Date: 2024-03-21 12:09:57
 * @LastEditTime: 2024-10-14 15:08:00
 * @LastEditors: cleardayyy 2270467108@qq.com
 */
import Vue from "vue";
import App from "./App.vue";
import "./global.css";
import VueRouter from "vue-router";
import router from "./page/router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import "./assets/tailwindcss.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(Antd); //全局注册
Vue.use(ElementUI);
new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");